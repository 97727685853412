import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";
import baseConfig from "../single-spa-aurelia/src/authentication/authConfig";
import {backendConstants} from "../single-spa-react/src/constants/backend.constants";

if(location.href===location.origin+"/"){
	window.location.replace(location.href+"#/");
} /*else if(location.href===location.origin+"/ia3"){
	window.location.replace(location.href+"/#/");
} else if(location.href===location.origin+"/ia3/"){
	window.location.replace(location.href+"#/");
}*/
registerApplication(
	"@ITT/single-spa-react",
	() => System.import(`${backendConstants.FE_ROOT_SINGLE_SPA_URL}`),
	() => isActive.react(location),
	{ domElement: document.getElementById("react-container") }
);
registerApplication(
  	"@ITT/single-spa-aurelia",
	() => System.import(`${baseConfig.frontEndSpaBaseUrl}single-spa/ITT-single-spa-aurelia-prod.js`),
	() => isActive.aurelia(location),
	{ domElement: document.getElementById("aurelia-container") }
);
start();